import React from 'react'

export default function Service() {
  return (
    <>
    <div className="token-pricing-section1" id="service">
        <div className="container-fluid">
          <div className="bottom-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="image text-center">
                  {/* <h5 class="text">All new arrival</h5> */}
                  <h2 className="texth2">HOW STAKING WORKS</h2>
                  <p>
                    {" "}
                    The point of ainu token isn't to just buy and trade, but to
                    stake your token in ainu token and bet on battles for the
                    chance to win big! <br />
                    Alongside our regular battles we will also have larger, more
                    hyped battles for people to participate in with large prizes
                    for winners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
