import React from "react";

export default function Roadmap() {
  return (
    <>
      <div className="road-map-section" id="roadmap">
        <div className="container">
          <div className="tatle text-center">
            <h4 className="text">WHAT CAN YOU EXPECT</h4>
            <h2 className="texth21">AINU TOKEN ROADMAP</h2>
            <h2 className="texth21">2024</h2>
          </div>
          <div className="container1">
            <div className="timeline-block timeline-block-right">
              <div className="marker" />
              <div className="timeline-content">
                <h2 className="texth4">PRESALE LAUNCH - Q3 2024</h2>
                <p>
                  Objective: start the ainu token project through a token
                  presale.
                </p>
                <p className="texth">
                  <b>Details:</b> 50% of the ainu tokens will be available for
                  presale, paving the way for the platform's launch.
                </p>
                <p className="texth">
                  <b>Outcome:</b> A successful presale will provide the
                  essential funds and community support required to move forward
                  with the platform's development.
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-left">
              <div className="marker" />
              <div className="timeline-content">
                <h2 className="texth4">PLATFORM LAUNCH - Q1 2024</h2>
                <p> Objective: Officially launch the ainu token platform.</p>
                <p className="texth">
                  <b>Details:</b> Depending on the completed sell-out of the
                  presale, the platform launch is planned for first quarter
                  2024.
                </p>
                <p className="texth">
                  <b>Outcome:</b>The launch will mark the commencement of ainu
                  token's operations, offering users access to battles, staking,
                  and other features.
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-right">
              <div className="marker" />
              <div className="timeline-content">
                <h2 className="texth4">SEASON 1 - IMMEDIATE POST-LAUNCH</h2>
                <p>Objective: Inaugurate the first season of Ainu token.</p>
                <p className="texth">
                  <b>Details:</b>Season 1 will kick off immediately following
                  the platform launch, featuring battles, leaderboards, and
                  more.
                </p>
                <p className="texth">
                  <b>Outcome:</b> This season will set the stage for continuous
                  gameplay, attracting players, and fostering community
                  engagement
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-left">
              <div className="marker" />
              <div className="timeline-content">
                <h2 className="texth4">SEASON 2 - Q2 2024</h2>
                <p>
                  Objective: Launch the second season of ainu token with
                  enhanced features and experiences.
                </p>
                <p className="texth">
                  <b>Details:</b> Building on the success of the first season,
                  Season 2 will commence in second quarter of 2024, introducing
                  new battle types, rewards, and opportunities.
                </p>
                <p className="texth">
                  <b>Outcome:</b> Season 2 will further establish ainu token's
                  presence in the blockchain gaming space, reflecting the
                  project's commitment to innovation and continuous improvement.
                </p>
              </div>
            </div>
            <div className="timeline-block timeline-block-right">
              <div className="marker" />
              <div className="timeline-content">
                <h2 className="texth4">FUTURE DEVELOPMENT</h2>
                <p>
                  Continuous Upgrades: Ainu token will continue to evolve, with
                  ongoing updates to the platform, gameplay, tokenomics, and
                  more.
                </p>
                <p className="texth">
                  <b>Community Engagement:</b>Ongoing interaction with the
                  community will shape future seasons and features, aligning
                  with player preferences and industry trends.
                </p>
                <p className="texth">
                  <b>Expansion Plans:</b> Looking past , the project has plans
                  for further expansion, potentially adding new game modes,
                  partnerships, and other exciting developments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
