import React, { useEffect, useState } from "react";

export default function TokenP() {

  const [time, setTime] = useState({
    days: 40,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    var countDownDate = new Date("Wed may 15 2024 10:00:19");
    countDownDate.setHours(countDownDate.getHours());
    countDownDate.setMinutes(countDownDate.getMinutes());

    var x = setInterval(function () {
      var now = new Date().getTime();

      var distance = countDownDate - now;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById("demo").innerHTML =
        days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

      if (distance < 0) {
        clearInterval(x);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 1000);
    return () => clearInterval(x);
  }, []);

  return (
    <>
      <div className="token-pricing-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-4">
              <div className="timerss">
                <center>
                  <div className="count-box">
                    <h3>Pre Sale Start Time</h3>
                      <div id="demo"></div>
                    {/* <button className="buttons" id="days">{`${time.days} days`}</button>
                    <button className="buttons" id="hours">{`${time.hours} hours`}</button>
                    <button className="buttons" id="mins" >{`${time.minutes} minutes`}</button>
                    <button className="buttons" id="secs" >{`${time.seconds} seconds`}</button> */}
                     {/* <button className="buttons" id="days"></button>
                    <button className="buttons" id="hours"></button>
                    <button className="buttons" id="mins" ></button>
                    <button className="buttons" id="secs" ></button> */}
                    <h1 id="end" />
                    <h3 className="text pad">UNTIL TOKEN LAUNCH</h3>
                    <h4 className="text bge pad">
                      {" "}
                      CHANCE TO BUY BEFORE LISTING
                    </h4>
                    <input
                      type="text"
                      className="co pad"
                      defaultValue="0x80979a2C218E0b3DADEc2cF1E8B1Cc362aC058Dd"
                      id="myInput"
                    />
                    <button className="cobtn" 
                    // onclick="myFunction()"
                    >
                      Copy
                    </button>
                    {/* <h3 class="text pad">
				    	CHOOSE YOUR CURRENCY
				    </h3> */}
                    <div className="tab">
                      {/* <button
                        className="tablinks"
                        onclick="openCity(event, 'London')">
                        {" "}
                        <img src="assets/image/bsc.svg" alt="" /> BNB
                      </button> */}
                      {/*  <button class="tablinks" onclick="openCity(event, 'Paris')">  <img src="assets/image/usdt.svg"> USDT</button> */}
                    </div>
                    <div
                      id="London"
                      className="tabcontent pad"
                      style={{ display: "block" }}>
                      <h4 className="text-black">1 Ainu Token = $0.0000650</h4>
                      <div className="dis">
                        <div>
                          <label className="text-left">
                            <b>USDT</b> you pay
                          </label>
                          <input
                            type="text"
                            className="imput"
                            defaultValue={0}
                            id="myInput"
                          />
                        </div>
                        <div className="">
                          <label className="text-left">
                            <b>Ainu Token</b> you receive
                          </label>
                          <input
                            type="text"
                            className="imput"
                            defaultValue={0}
                            id="myInput"
                          />
                        </div>
                      </div>
                      <div className="pad bg">
                        <a href="" className="text-black">
                          Connect Wallet
                        </a>
                      </div>
                      <div className="pad bg">
                        <a href="" className="text-black">
                          {" "}
                          <img src="assets/image/bsc.svg" alt="" /> Buy on BSC
                        </a>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="token-section">
        <div className="container-fluid">
          <div className="tatle">
            <h4 className="text">Token</h4>
            <h2 className="texth2">Token Economics</h2>
          </div>
          <div className="token-content wow fadeInLeft" data-wow-delay=".4s">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div
                  className="left-content wow fadeInLeft bgw"
                  data-wow-delay=".4s">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="content">
                        <div className="icon d-flex">
                          <i className="fa fa-circle" aria-hidden="true" />
                          <h4 className="text-black">Name</h4>
                        </div>
                        <p className="text-black">Ainu</p>
                        <div className="bar" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="content wow fadeInLeft"
                        data-wow-delay=".4s">
                        <div className="icon d-flex">
                          <i className="fa fa-circle" aria-hidden="true" />
                          <h4 className="text-black">Symbol</h4>
                        </div>
                        <p className="text-black">Ainu</p>
                        <div className="bar" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div
                        className="content wow fadeInLeft"
                        data-wow-delay=".4s">
                        <div className="icon d-flex">
                          <i className="fa fa-circle" aria-hidden="true" />
                          <h4 className="text-black">Chain</h4>
                        </div>
                        <p className="text-black">BNB</p>
                        <div className="bar" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="content">
                        <div className="icon d-flex">
                          <i className="fa fa-circle" aria-hidden="true" />
                          <h4 className="text-black">Total Supply</h4>
                        </div>
                        <p className="text-black">140000000000</p>
                        <div className="bar" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="content">
                        <div className="icon d-flex">
                          <i className="fa fa-circle" aria-hidden="true" />
                          <h4 className="text-black">Decimal</h4>
                        </div>
                        <p className="text-black">18</p>
                        <div className="bar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="">
                  <center>
                    <img src="assets/image/tk.png" className="wii" alt="" />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
