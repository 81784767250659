import React from "react";

export default function Main() {
  return (
    <>
      <div className="banner-section" id="home">
        <div className="container-fluid">
          <div className="content-wraper">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="left-content">
                  {/* <h4 class="text">STAKE, PLAY & GET REWARDS</h4> */}
                  <h1 className="texth1">
                    STAKE, PLAY &amp; <span className="text"> GET </span>{" "}
                    REWARDS
                  </h1>
                  <p className="text-black">
                    Ainu is an innovative new gaming platform designed for
                    gaming enthusiasts. From active betting to passive staking,
                    there are rewards for all users
                  </p>
                  {/* <div class="button">
							<a href="#">Read More</a>
							<a class="right" href="#">Contact Us</a>
						</div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="right-content">
                  <div className="image">
                    <img src="assets/image/logo1.png" alt="" width="80%" />
                  </div>
                  {/* <div class="shape-image1 bounce bounce-animate">
							<img src="assets/image/s-1.png" alt="">
						</div>

						<div class="shape-image2 bounce bounce-animate3">
							<img src="assets/image/s-1-Copy.png" alt="">
						</div>

						<div class="shape-image3 bounce bounce-animate">
							<img src="assets/image/s-1-Copy(2).png" alt="">
						</div>

						<div class="shape-image4 bounce bounce-animate3">
							<img src="assets/image/s-1-Copy(3).png" alt="">
						</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
