import React from "react";

export default function OurToken() {
  return (
    <>
      <div className="token-pricing-section" id="token">
        <div className="container">
          <div className="tatle">
            <h4 className="text">Our Token</h4>
            <h2 className="texth2">TOKENOMICS</h2>
          </div>
          <div className="token-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="text-right">
                  <h4 className="texth4">50% PRESALE</h4>
                  <p className="tokenp">
                    Half of total token supply will be set aside for the ainu
                    token presale. The intention is for all who buy presale to
                    participate in ainu token.
                  </p>
                </div>
                <hr className="hr" />
                <div className="text-right">
                  <h4 className="texth4">30% STAKING &amp; BATTLE REWARDS</h4>
                  <p className="tokenp">
                    We have set aside a large percentage of the supply to
                    support that ecosystem and help maintain balance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="">
                  <center>
                    <img src="assets/image/token.png" width="100%" alt="" />
                  </center>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="text-left">
                  <h4 className="texth4">10% DEX</h4>
                  <p className="tokenp">
                    10% of the overall supply is reserved for pairing with a
                    liquidity pool on a decentralized exchange.
                  </p>
                </div>
                <hr className="hr" />
                <div className="text-left">
                  <h4 className="texth4">10% COMMUNITY REWARDS</h4>
                  <p className="tokenp">
                    ainu token is all about participation and community
                    engagement through the love of epic battles. So we have set
                    aside a good amount of tokens for community rewards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
