import React from "react";

export default function SectionMid() {
  return (
    <>
      <div className="token-pricing-section1">
        <div className="container-fluid">
          <div className="bottom-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="imas">
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im.png" className="wi" alt="" />
                  {/* <h5 class="texth5 trs">Aniu Dog</h5> */}
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im1.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im2.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im3.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im5.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
            </div>
            <br />
            <div className="imas">
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im6.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im8.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im7.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im9.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
              <div className="">
                <div className="imagess">
                  <img src="assets/image/im10.png" className="wi" alt="" />
                  <h4 className="bt trs1">Grab It Now</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="token-pricing-section1">
        <div className="container-fluid">
          <div className="bottom-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="row">
              <div className="col-md-6">
                <div className="">
                  <div className="image">
                    <img src="assets/image/ab.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <div className="image">
                    {/* <h5 class="text">Yes we do sell Nft</h5> */}
                    <h2 className="texth2">CHOOSE YOUR VICTOR</h2>
                    <p>
                      {" "}
                      In Season 1 we have 11 Memes all fighting to be #1. Will
                      you find a favorite? Or play the odds?{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
