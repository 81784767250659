import React from "react";

export default function About() {
  return (
    <>
      <div className="token-pricing-section" id="about">
        <div className="container-fluid">
          <div
            className="bottom-content bgss wow fadeInLeft"
            data-wow-delay=".5s">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="image"></div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-content pt-50">
                  {/* <h5 class="text">We are Ainu</h5> */}
                  <h2>
                    EARN APY DURING <br />
                    PRESALE
                  </h2>
                  <p className="text-white">
                    Do not wait for battles, begin earning APY on ainu token
                    tokens <br />
                    today by buying the presale. Click here to learn more!
                  </p>
                  {/* <div class="button">
							<a href="#">Read More</a>
						</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
