import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { Modal } from "antd";
// import {
//   InstagramOutlined,
//   WhatsAppOutlined,
//   FacebookOutlined,
//   TwitterOutlined,
// } from "@ant-design/icons";
import { DashMain } from "../DashMain/DashMain";
import DashHeader from "../DashHeader/DashHeader";
import DOMPurify from "dompurify";
import { loadWeb3 } from "../../Utility/contract";
import toast from "react-hot-toast";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Withdrawal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const [amount, setAmount] = useState("");
  const [token, setToken] = useState("");
  const [connected, setConnected] = useState("Wallet is locked");
  const [Wallet_Address, setWallet_Address] = useState("");


  const [visible, setVisible] = useState(false);
  const [socialLink, setSocialLink] = useState("");


  const [dash, setdataArray] = useState([]);
  


  async function DashboardAPI() {
    try {
      let responce = await API?.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwtToken}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      // console.log("Dash res-->", responce);

      setdataArray(responce);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            AinuAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  }
  




  const handleAmount = (e) => {
    const value1 = DOMPurify.sanitize(
      e.target.value.replace(/[^0-9]/gi, "").substring(0, 6)
    );
    setAmount(value1);
    setAmount((prev) => {
      if (prev !== value1) {
        // calculateToken(value1);
        return value1;
      }
      return prev;
    });
  };

  const WalletConnected = async () => {
    try {
      const acc = await loadWeb3();
      if (acc === "No Wallet") {
        setConnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error("Wrong Network. Please Connect BSC Network");
        setConnected("Wrong Network");
      } else {
        setConnected("Wallet is Connected");
      }
    } catch (error) {
      console.error("Error while connecting wallet:", error);
    }
  };

  const Withdrawal_API = async () => {
    try {
      const acc = await loadWeb3();
      if (!acc) {
        toast.error("No Wallet");
        return;
      }

      if (acc === "Wrong Network") {
        toast.error("Wrong Network. Please Connect Polygon Scan Network");
        return;
      }

      if (acc.toUpperCase() !== Wallet_Address.toUpperCase()) {
        toast.error("Wallet address does not match");
        return;
      }

      if (amount <= 0) {
        toast.error("Invalid amount");
        return;
      }
      // let link = null;
      // if (status === 1 && socialLink === "") {
      //   toast.error("Enter Social Link !!");
      //   return;
      // } else if (status === 1 && socialLink !== "") {
      //   link = socialLink;
      // }

      const res = await API.post(
        "/withdrawal",
        {
          uid: userId,
          amount: 0,
          tokenvalue: amount,
          BNBvalue: 0,
          txn: "",
          type: "Netbalance",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Withdrawal API", res);
      if (res.data.data.result === "SUCCESSFUL") {
        toast.success("Withdrawal is successful");
      } else {
        toast.error(res.data.data.result);
      }
      // window.location.reload();

      // navigate("/Dashboard");
    } catch (error) {
      console.error("Error while withdrawing:", error);
      dispatch(
        updateAuth({
          AinuAuth: false,
          userId: null,
          jwtToken: null,
          ipAddress: null,
        })
      );
      navigate("/");
    } finally {
      setAmount("");
      setToken(0);
      // setSocialLink(null);
    }
  };

  useEffect(() => {
    WalletConnected();
    DashboardAPI();
  }, []);

  // const handleModalVisible = () => {
  //   setVisible(false);
  //   Withdrawal_API(0);
  // };

  // const handleSocialLink = (e) => {
  //   let value1 = DOMPurify.sanitize(e.target.value);
  //   setSocialLink(value1);
  // };
  // console.log("socialLink", handleSocialLink);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="container-fluid pt-5 dashboardSection">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12 h-100 d-flex align-items-center justify-content-center flex-column">
                  <h2 className="headingDashboard fw-bold py-3">
                    <span>Withdrawal</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 " />
            <div className="col-md-6 ">
              <div className="modal-dialog ">
                <div className="modal-content exchange_width backgroundcolor">
                  <div className="modal-header exchange_hd w-100 flex-wrap ">
                    <h4 className="text-Success w-100 text-center pt-2">{connected}</h4>
                    <p className="text-white fs-16 text-center w-100">
                      Available Net Balance : {dash.Net_Balance} AINU
                    </p>
                  </div>
                  <div className="modal-body">
                    {/* <form
                      className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0"
                      ng-submit="withDrawalAmount()">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-md-8 col-6">
                            <input
                              type="number"
                              className="form-control shadow-none"
                              id="amountInput"
                              min={1}
                              step={1}
                              maxLength={4}
                              defaultValue=""
                              style={{ width: "90%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0 pt-3">
                          <div className="col-6 row align-items-center justify-content-center" />
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: You can withdrawal minimum 10 $.
                            </p>
                          </div>
                        </div>
                      </div>
                    </form> */}
                    <div className="row mx-0 align-items-center justify-content-between my-3">
                      <div className="col-md-6 px-1">
                        <label
                          className="text-white label fs-6"
                          htmlFor="amountInput1">
                          Enter AINU Amount
                        </label>
                      </div>
                      <div className="col-md-6 px-1">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          id="amountInput"
                          min={1}
                          step={1}
                          placeholder="Enter AINU Amount"
                          value={amount}
                          onChange={handleAmount}
                        />
                      </div>
                    </div>

                    {/* {parseInt(top_up) === 1 && ( */}
                    <div className="row align-items-center justify-content-center mx-0 pt-4">
                      <div className="col-sm-6 row align-items-center justify-content-center">
                        <button
                          className="btn btn-danger withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                          onClick={() => Withdrawal_API()}>
                          Withdrawal
                        </button>
                      </div>
                    </div>
                    {/* )} */}

                    {/* <Modal open={visible} footer={false} closeIcon={null}>
                      <div className="container-fluid Activate_UpgradeSectiob">
                        <div className="row">
                          <div className="card">
                            <div className="card-body">
                              <div className="">
                                <h3 className="text-light text-center">
                                  Share Social Media Link
                                </h3>
                              </div>
                              <div className="social-icons ">
                                <InstagramOutlined />
                                <FacebookOutlined />
                                <WhatsAppOutlined />
                                <TwitterOutlined />
                              </div>
                              <div className="row mx-0 align-items-center justify-content-between">
                                <div className="col-md-12 px-1">
                                  <input
                                    type="text"
                                    className="form-control shadow-none w-100 text-black border-theme1"
                                    defaultValue={socialLink}
                                    onChange={(e) => handleSocialLink(e)}
                                  />
                                </div>
                              </div>

                              <div className="d-flex align-items-center justify-content-end gap-1 mx-0 pt-4 ">
                                <button
                                  className="btn btn-theme1 withdraw_btn shadow-none"
                                  // onClick={() => Withdrawal_API(1)}
                                  >
                                  Submit
                                </button>
                                <button
                                  className="btn btn-danger withdraw_btn shadow-none"
                                  // onClick={() => handleModalVisible()}
                                  >
                                  Skip
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
