import React from "react";

export default function Contact() {
  return (
    <>
      <div className="contact-section" id="contact">
        <div className="container-fluid">
          <div className="bottom-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="row">
              <div className="col-md-6">
                <div className="">
                  <div className="image1 bgw">
                    <h5 className="text1">Stay Engaged</h5>
                    <h2 className="text2">
                      GET THE LATEST NEWS AND UPDATE ABOUT AINU OFFICIAL STAY
                      CONNECTED
                    </h2>
                    <div className="contact-wraper">
                      <form action="#" method="POST" id="dreamit-form">
                        <div className="row">
                          <div className="col-lg-8 col-md-8">
                            <div className="form_box">
                              <input
                                className="form-control contact"
                                type="text"
                                name="name"
                                placeholder="Enter Email Id"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="contact-button">
                              <button className="btn" type="submit">
                                {" "}
                                Subscribe{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        <br />
                        {/* <h4 class="text">
										We will contact you back within 24hrs via email.
									</h4> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact">
                  <center>
                    <img src="assets/image/contact.png" alt=""/>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section">
        <div className="container-fluid">
          <div className="bottom-content wow fadeInLeft" data-wow-delay=".5s">
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <div className="text-center bgw">
                  <div className="image">
                    <h5>Join us right now!</h5>
                    <h2 className="texth21">JOIN THE COMMUNITY</h2>
                    <p>
                      {" "}
                      Join us in the arena and stay connected with exclusive
                      <br /> content and updates in our communities!
                    </p>
                  </div>
                  <div className="">
                    <a
                      href="https://www.facebook.com/profile.php?id=61556572754612"
                      target="_blank"
                      rel="noreferrer">
                      <i className="fa-brands fa-facebook-f ic" />
                    </a>
                    <a
                      href="https://www.instagram.com/ainu_coin/"
                      target="_blank"
                      rel="noreferrer">
                      <i className="fa-brands fa-instagram ic" />
                    </a>
                    <a href="" target="_blank">
                      <i className="fa-brands fa-x-twitter ic" />
                    </a>
                    <a
                      href="https://www.reddit.com/r/ainucrypto/comments/1b6xnh0/ainu_token_coming_soon/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button"
                      target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-reddit-alien ic" />
                    </a>
                    <a href="https://t.me/+lF7FxensS9NlYTNl" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-telegram ic" />
                    </a>
                    <a
                      href="https://medium.com/@ainu0574/ainu-token-coming-soon-c75e606313c9"
                      target="_blank" rel="noreferrer">
                      <i className="fab fa-medium ic" />
                    </a>
                    <a href="https://discord.gg/8g3RASRX" target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-discord ic" />
                    </a>
                    {/* <img src="assets/image/discord.png" class="ic" width="34px"> */}
                    <a
                      href="https://youtube.com/@ainu-zy7eh?si=07en6hMONhhYUoK4"
                      target="_blank" rel="noreferrer">
                      <i className="fa-brands fa-youtube ic" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
