import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="cuppy-right-section">
            <div className="footer-bar" />
            <p className="text-white">
              Copyright ©2024 Ainu. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
