import React, { useEffect } from "react";
import Header from "../Landing component/Header";
import Main from "../Landing component/Main";
import TokenP from "../Landing component/TokenP";
import About from "../Landing component/About";
import Service from "../Landing component/Service";
import SectionMid from "../Landing component/SectionMid";
import OurToken from "../Landing component/OurToken";
import Roadmap from "../Landing component/Roadmap";
import Faq from "../Landing component/Faq";
import Contact from "../Landing component/Contact";
import Footer from "../Landing component/Footer";

export default function Landing() {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {};

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />
      {/* ======================banner section start========================== */}
      <Main />
      <TokenP />
      <About />
      <Service />

      <br />
      <br />
      <br />
      <SectionMid />
      <OurToken />

      <Roadmap />
      {/* <Faq /> */}
      <Contact />
      {/* scroll strat============  */}
      <div className="scroll-area">
        <div className="top-wrap">
          <div className="go-top-btn-wraper">
            <div className="go-top go-top-button" onClick={scrollToTop}>
              <i className="fa fa-angle-double-up" aria-hidden="true" />
              <i className="fa fa-angle-double-up" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
      {/* scroll end============  */}
      {/* ======================banner section end========================== */}
      <Footer />
    </>
  );
}
