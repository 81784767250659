import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import { Login } from "./Components/Login/Login";
import { Register } from "./Components/Register/Register";
import { DashMain } from "./Components/DashMain/DashMain";
import { Buy } from "./Components/Buy/Buy";
import { Ref_income } from "./Components/Ref_income/Ref_income";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import Withdrawal_History from "./Components/Withdrawal_History/Withdrawal_History";
import { Toaster } from "react-hot-toast";
import Privateroutes from "./Utility/Privateroutes";
import BuyTokenHistory from "./Components/Buy_Token_History.jsx/History";
import MyDirect from "./Components/My Direct/MyDirect";

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/Login" element={<Login />}></Route>
        <Route exact path="/Register" element={<Register />}></Route>
        <Route element={<Privateroutes/>}>
        <Route exact path="/Dashboard" element={<DashMain />}></Route>
        <Route exact path="/Buy" element={<Buy />}></Route>
        <Route exact path="/Ref_income" element={<Ref_income />}></Route>
        <Route exact path="/mydirect" element={<MyDirect />} />
        <Route exact path="/withdrawal" element={<Withdrawal />} />
        <Route exact path="/withdrawalHistory" element={<Withdrawal_History />} />
        <Route exact path="/BuyTokenHistory" element={<BuyTokenHistory />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
