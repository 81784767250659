import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo1 from "../Assets/logo1.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";

function DashHeader() {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        token: null,
        userId: null,
        ipAddress: null,
      })
    );
  }
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo1} alt="" className="img-fluid imgBrand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="jkjsnfkse">
          <Nav className="">
            <Link to="/Dashboard">
              {" "}
              <Nav.Link href="/Dashboard">Dashboard</Nav.Link>
            </Link>
            {/* <NavDropdown title="Team" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Level Details</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                My Direct
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <NavDropdown title="Activate" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Activation</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              Activation History
              </NavDropdown.Item>
            </NavDropdown> */}
            {/* <Link to="/Buy">
              {" "}
              <Nav.Link href="/Buy">Buy</Nav.Link>
              <Nav.Link href="/BuyTokenHistory">Buy History</Nav.Link>
            </Link> */}

            <NavDropdown
              className="navhxnn"
              style={{ display: "content !important" }}
              title="Buy"
              id="collapsible-nav-dropdown">
              <Link to="/Buy">
                <NavDropdown.Item href="/Buy">Deposite</NavDropdown.Item>
              </Link>
              <Link to="/BuyTokenHistory">
                <NavDropdown.Item href="/BuyTokenHistory">
                  Deposite History
                </NavDropdown.Item>
              </Link>
            </NavDropdown>

            <NavDropdown title="Income" id="collapsible-nav-dropdown">
              <Link to="/Ref_income">
                {" "}
                <NavDropdown.Item href="/Ref_income">
                  Direct Income
                </NavDropdown.Item>
              </Link>
              <Link to="/mydirect">
                {" "}
                <NavDropdown.Item href="/mydirect">
                 My Direct
                </NavDropdown.Item>
              </Link>
            </NavDropdown>
            <NavDropdown title="Withdrawal" id="collapsible-nav-dropdown">
              <Link to="/withdrawal">
                <NavDropdown.Item href="/withdrawal">
                  Withdrawal
                </NavDropdown.Item>
              </Link>
              <Link to="/withdrawalHistory">
                <NavDropdown.Item href="/withdrawalHistory">
                  Withdrawal History
                </NavDropdown.Item>
              </Link>
            </NavDropdown>
            <Link to="/">
              {" "}
              <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default DashHeader;
